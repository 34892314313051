.fs-base{
  font-size: 14px;
}

.fs-md{
  font-size: 16px;
}

.fs-lg{
  font-size: 18px;
}

.fw-400{
  font-weight: 500;
}

.fw-500{
  font-weight: 500;
}

.fw-600{
  font-weight: 600;
}

.cursor-pointer{
  cursor: pointer;
}

.action-theme {
  color: #1954a9;
  cursor: pointer;
}

.overflow-y-auto{
  overflow-y: auto;
}

.status-chip-theme{
  height: 24px;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  padding: 2px 4px;
  &.success{
    color: #224F24;
    background-color: #EDF7ED;
  }
  &.warning{
    color: #675D25;
    background: #F7F1CB;
  }
  &.cancelled{
    color: #771818;
    background: #F9E9E9;
  }
}

.note-content {
  background: #ECF7FF;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: flex-start;
  margin-bottom: 24px;

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    white-space: pre-wrap;
  }

  .icon {
    font-size: 20px;
    color: #1954A9;
    margin-right: 8px;
    margin-top: 2px;
  }
}
