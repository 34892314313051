@use '~@angular/material'as mat;
@import "~@angular/material/theming";
@import "~src/styles/global/_color.scss";
@import 'qms-angular/lib.theme';
@import 'qms-angular/src/themes/_non-bg-scrollbar';
@import 'qms-angular/src/themes/_qms-icon-font';
@include mat.core();

$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$app-warn: mat.define-palette(mat.$red-palette);

$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

@include mat.all-component-themes($app-theme);

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.toast-error {
  background-color: var(--error-color);
  color: var(--text-white);

  .mat-simple-snackbar-action  {
    color: var(--text-white);
  }
}

.toast-success {
  background-color: var(--success-color);
  color: var(--text-white);

  .mat-simple-snackbar-action  {
    color: var(--text-white);
  }
}

.toast-infor {
  background-color: var(--infor-color);
  color: var(--text-white);

  .mat-simple-snackbar-action  {
    color: var(--text-white);
  }
}

.toast-warning {
  background-color: var(--warning-color);
  color: var(--text-white);

  .mat-simple-snackbar-action  {
    color: var(--text-white);
  }
}

.w-33 {
  width: 33.33% !important;
}
